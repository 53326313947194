// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { IshiharaRecord, IshiharaEyeState, MonitorEvent, IshiharaState } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, EYE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

@Component({
  selector: 'app-monitor-ishihara',
  templateUrl: './monitor-ishihara.component.html'
})
export class MonitorIshiharaComponent extends MonitorBaseComponent {

  odResponses: IshiharaRecord[] = [];
  osResponses: IshiharaRecord[] = [];
  ouResponses: IshiharaRecord[] = [];

  currentSeqNum = null;

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.COLOR_VISION * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.monitorTestService.getIshiharaState(this.test.id).subscribe(response => {
      this.updateResponses(response.eye_states);
      this.currentSeqNum = response.seq_num;
    });
  }

  public getPercentageDone(responses: IshiharaRecord[]) {
    if (!responses.length)
      return 0;

    const recordsWithValues = responses.filter(response => !!response.answered);
    return (Math.min((recordsWithValues.length / responses.length) * 100, 100)).toFixed(0);
  }

  public getCorrect(eye: 'OS' | 'OD' | 'OU'): number {
    let count: number;
    switch (eye) {
      case EYE.OS:
        count = this.osResponses.filter(response => response.answered && (response.value === response.answer)).length;
        break;
      case EYE.OD:
        count = this.odResponses.filter(response => response.answered && (response.value === response.answer)).length;
        break;
      case EYE.OU:
        count = this.ouResponses.filter(response => response.answered && (response.value === response.answer)).length;
    }
    return count;
  }

  public getAnswered(eye: 'OS' | 'OD' | 'OU'): number {
    let count: number;
    switch (eye) {
      case EYE.OS:
        count = this.osResponses.filter(response => response.answered).length;
        break;
      case EYE.OD:
        count = this.odResponses.filter(response => response.answered).length;
        break;
      case EYE.OU:
        count = this.ouResponses.filter(response => response.answered).length;
        break;
    }
    return count;
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_ISHIHARA_STATE) {
      const eventSeqNum = (<IshiharaState>event.data).seq_num;
      if (!eventSeqNum || !this.currentSeqNum || (this.currentSeqNum <= eventSeqNum)) {
        this.currentSeqNum = eventSeqNum;
        this.updateResponses((<IshiharaState>event.data).eye_states);
      }
    }
  }

  private updateResponses(items: IshiharaEyeState[]) {
    items.forEach(item => {
      switch (item.eye) {
        case EYE.OS:
          this.osResponses = item.responses;
          break;
        case EYE.OD:
          this.odResponses = item.responses;
          break;
        case EYE.OU:
          this.ouResponses = item.responses;
          break;
      }
    });
  }

  public resetStateAfterReconnection(): void { }

  public calculateDimensions() { }

  public onToggleGrid() { }
}