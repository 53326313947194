<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--limit-height">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div @startList class="monitor_list-container">
        <div
          *ngFor="let record of leftEarRecords.slice().reverse(), index as i" 
          @fadeAnimation 
          class="monitor_list-item"
          [ngClass]="{'hidden': isLeftEarRecordHidden(record, i)}">
          <ng-container *ngIf="record, else noRecordTemplate">
            <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
          </ng-container>
        </div>
        <small *ngIf="!isLoading && !leftEarRecords?.length">{{ isTestDone ? ('noTestDataReceived' | translate) : ('noTestData' | translate) }}</small>
      </div>
    </div>
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div @startList class="monitor_list-container">
        <div 
          *ngFor="let record of rightEarRecords.slice().reverse(), index as i"
          @fadeAnimation 
          class="monitor_list-item"
          [ngClass]="{'hidden': isRightEarRecordHidden(record, i)}">
          <ng-container *ngIf="record, else noRecordTemplate">
            <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
          </ng-container>
        </div>
        <small *ngIf="!isLoading && !rightEarRecords?.length">{{ isTestDone ? ('noTestDataReceived' | translate) : ('noTestData' | translate) }}</small>
      </div>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone()">
      </app-percentage-circle>
    </div>
  </div>
</div>

<ng-template #recordView let-record="record">
  <i 
    class="fas fa-headphones" 
    [ngClass]="{ 
        'orange': !record?.is_heard || record.response_time < 0.15, 
        'color-primary': record?.is_heard && record.response_time > 0.15
      }"></i>
  <span 
    matTooltip="{{ 'frequency' | translate | titlecase }}" 
    class="pointer">
    {{ record.frequency + ' hZ' }}
  </span>

  <span class="pointer" matTooltip="{{ 'volume' | translate | titlecase }}">
    {{ record.volume_db + ' dB' }}
  </span>

  <span>
    {{ (record?.is_heard && record.response_time > 0.15) ? ('heard' | translate | titlecase) : ('unheard' | translate | titlecase) }}
  </span>
</ng-template>

<ng-template #noRecordTemplate>
  <span class="text--blink">{{'syncing' | translate | titlecase}}...</span>
</ng-template>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>

