// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { BaaAgreementComponent } from "./baa-agreement/baa-agreement.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { SsoSuccessComponent } from './sso-success/sso-success.component';
import { SsoErrorComponent } from './sso-error/sso-error.component';
import { SsoOnboardingComponent } from './sso-onboarding/sso-onboarding.component';
import { DuoRedirectComponent } from "./duo-redirect/duo-redirect.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    BaaAgreementComponent,
    LoginComponent,
    ResetPasswordComponent,
    SsoSuccessComponent,
    SsoErrorComponent,
    SsoOnboardingComponent,
    DuoRedirectComponent
  ]
})
export class AuthModule { }