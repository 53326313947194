// native
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { UsersService } from 'app/core/services/users.service';
import { OfficesService } from 'app/core/services/offices.service';
import { ErrorService } from 'app/core/services/error.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { CenterContainerTitle, IEmployee, IEmployeeRequest, Office, PaginatedItems } from '../../models';

// constants
import { EMPLOYEE_GROUPS } from '../../constants';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit, OnChanges {

  @Input() user: IEmployee;
  @Input() action: string;
  @Input() preselectedOfficeId?: number;

  @Output() close: EventEmitter<IEmployee> = new EventEmitter<IEmployee>();

  form: UntypedFormGroup;
  offices: Office[];
  employeeGroups = EMPLOYEE_GROUPS;

  title: CenterContainerTitle = {};

  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usersService: UsersService,
    private officesService: OfficesService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.translateService.get('user').subscribe(() => this.setTranslations());

    this.officesService.getAll().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((offices: PaginatedItems<Office>) => {
      this.offices = offices.results;
      this.initializeForm();
    }, error => this.errorService.handleError(error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('user');
  }

  public initializeForm() {
    const { first_name, last_name, username, email, phone_number, offices, groups } = this.user || ({} as IEmployee);

    this.form = this.formBuilder.group({
      first_name: [first_name, Validators.required],
      last_name: [last_name, Validators.required],
      username: [username, Validators.required],
      email: [email, Validators.compose([Validators.required, Validators.email])],
      phone_number: [phone_number],
      offices: [offices, Validators.required],
      employeeGroup: [groups ? groups[0] : null, Validators.required]
    });

    if (!this.user && this.preselectedOfficeId)
      this.form.controls['offices'].setValue([this.preselectedOfficeId]);
    else if (!this.user)
      this.form.controls['offices'].setValue([this.offices[0]?.id]);

    if (!!this.user)
      this.form.markAllAsTouched();
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let { first_name, last_name, username, email, phone_number, offices, employeeGroup } = this.form.value;

    const groups = employeeGroup ? [employeeGroup] : employeeGroup;

    const body: IEmployeeRequest = {
      first_name,
      last_name,
      username,
      email: email?.toLowerCase ? email.toLowerCase() : null,
      phone_number: phone_number || null,
      offices: this.authService.isOfficeAdmin ? offices.filter(office => this.offices.map(o => o.id).includes(office)) : offices,
      groups
    };

    const request = this.user ? this.usersService.update(this.user.id, body) : this.usersService.create(body);

    this.isLoading = true;
    request.pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => this.close.emit(response),
      error => this.errorService.handleUserError(this.form, error)
    );
  }
}
