// native
import { Observable, of, ReplaySubject, Subscription } from "rxjs";
import { catchError, startWith } from "rxjs/operators";

// constants
import { PATIENT_JOB_STATUS } from "../constants";

export interface Patient {
  id: number;
  first_name: string;
  last_name: string;
  last_test: string;
  age: number;
  email: string;
  date_of_birth: string;
  gender: Gender;
  ethnicity: Ethnicity;
  office_group: number;
  doctor_id: number;
  active: boolean;
  trend_analysis_available: boolean;
  monocular?: boolean;
  phone?: string;
  patient_id_number?: string;
  active_techall_session?: boolean;
  subtitles?: boolean;
  is_encrypted?: boolean;
  encrypted_pii?: EncryptedPatientData;
  ipd?: number;
}

export interface EncryptedPatientData {
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  email?: string;
  phone?: string;
}

export interface PatientRequest {
  first_name?: string;
  last_name?: string;
  email?: string;
  date_of_birth?: string;
  gender_id?: number;
  ethnicity_id?: number;
  doctor_id?: number;
  monocular?: boolean;
  phone?: string;
  patient_id_number?: string;
  subtitles?: boolean;
  is_encrypted?: boolean;
  encrypted_pii?: EncryptedPatientData;
  needs_match_review?: boolean;
}

export interface DemoPatientRequest {
  email: string;
  name: string;
}

export interface PatientButtonActions {
  nameThin: string;
  nameBold: string;
  action: Function;
}

export interface Ethnicity {
  id: number;
  name: string;
  active: boolean;
}

export interface Gender {
  id: number;
  name: string;
  active: boolean;
}

export interface IPatientJob {
  id: number;
  status$: Observable<PATIENT_JOB_STATUS>;
  error$: Observable<string>;
  snapshot: PatientJobSnapshot;
  run(): void;
}

type PATIENT_JOB_STATUS = 'QUEUED' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';

interface PatientJobSnapshot {
  status: PATIENT_JOB_STATUS;
  error: string;
}

export class PatientJob implements IPatientJob {
  public id: number;
  public status$: Observable<PATIENT_JOB_STATUS>;
  public error$: Observable<string>;
  public snapshot: PatientJobSnapshot;

  protected statusSource = new ReplaySubject<PATIENT_JOB_STATUS>(1);
  protected errorSource = new ReplaySubject<string>(1);
  protected uploadSubscription: Subscription;

  constructor(
    public action: Function,
    public patient: Patient
  ) {
    this.id = patient.id;
    this.snapshot = { error: '', status: <PATIENT_JOB_STATUS>PATIENT_JOB_STATUS.QUEUED };
    this.status$ = this.statusSource.pipe(
      startWith(<PATIENT_JOB_STATUS>PATIENT_JOB_STATUS.QUEUED)
    );
    this.error$ = this.errorSource.pipe(
      startWith('')
    );
  }

  run(): void {
    this.setStatus(<PATIENT_JOB_STATUS>PATIENT_JOB_STATUS.IN_PROGRESS);
    this.uploadSubscription = this.action(this.patient).pipe(
      catchError(err => {
        this.setError('fileUploadError');
        this.setStatus(<PATIENT_JOB_STATUS>PATIENT_JOB_STATUS.FAILED);
        return of(err);
      }),
    ).subscribe(() => {
      this.setStatus(<PATIENT_JOB_STATUS>PATIENT_JOB_STATUS.SUCCEEDED);
    });
  }

  protected setStatus(status: PATIENT_JOB_STATUS) {
    const finished = [PATIENT_JOB_STATUS.SUCCEEDED, PATIENT_JOB_STATUS.FAILED].includes(status);
    this.snapshot.status = status;
    this.statusSource.next(status);

    if (finished) {
      this.statusSource.complete();
      this.errorSource.complete();
    }
  }

  protected setError(error: string) {
    this.errorSource.next(error);
    this.snapshot.error = error;
  }
}