//  native
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';

//  models
import { MonitorAction } from '../../../models';

// constants
import { TEST_ACTION } from '../../../constants';

@Component({
  selector: 'app-volume-control',
  templateUrl: './volume-control.component.html',
})
export class VolumeControlComponent implements OnChanges {

  @Input() volume: number = 0;
  @Input() deviceIpd: number = 0;
  @Input() patientIpd: number = 0;

  @Output() actionClicked: EventEmitter<MonitorAction> = new EventEmitter<MonitorAction>();

  storedVolume: number = null;
  isMuted: boolean = false;

  options: Options = {
    floor: 0,
    ceil: 100,
    hidePointerLabels: true,
    hideLimitLabels: true,
    animate: false
  };

  ngOnInit(){
    this.isMuted = this.volume === 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['volume']?.currentValue !== null && changes['volume']?.currentValue !== undefined) {
      this.isMuted = changes['volume'].currentValue === 0;
    }
  }

  toggleMute() {
    this.isMuted = !this.isMuted;

    if (this.isMuted) {
      this.storedVolume = this.volume;
      this.volume = 0;
    } else {
      this.volume = this.storedVolume;
    }

    this.actionClicked.emit({ value: TEST_ACTION.VOLUME.value, payload: this.isMuted ? '0' : this.storedVolume.toString() });
  }

  onVolumeChange(change: ChangeContext) {
    this.volume = change.value;
    this.isMuted = this.volume === 0;
    this.actionClicked.emit({ value: TEST_ACTION.VOLUME.value, payload: change.value?.toString() });
  }
}