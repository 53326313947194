// native
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// component
import { LoginComponent } from 'app/auth/login/login.component';
import { PatientsComponent } from 'app/patients/patients.component';
import { NotFoundComponent } from 'app/notfound/notfound.component';
import { TestsComponent } from 'app/tests/tests.component';
import { ResetPasswordComponent } from 'app/auth/reset-password/reset-password.component';
import { UserSettingsFormComponent } from './settings/user-settings/user-settings-form.component';
import { AboutComponent } from 'app/about/about.component';
import { DevicesComponent } from './devices/devices.component';
import { MonitorPerimetryComponent } from './monitor/monitor-perimetry/monitor-perimetry.component';
import { MonitorVisualAcuityComponent } from "./monitor/monitor-acuity-test/monitor-acuity-test.component";
import { HelpComponent } from './help/help.component';
import { OfficesComponent } from './offices/offices.component';
import { UsersComponent } from './users/users.component';
import { EditOfficeComponent } from './offices/edit-office/edit-office.component';
import { NewOfficeComponent } from './offices/new-office/new-office.component';
import { NewUserComponent } from './users/new-user/new-user.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { SelectOfficeComponent } from './offices/select-office/select-office.component';
import { AssignAdminFormComponent } from './users/assign-admin-form/assign-admin-form.component';
import { NewDeviceComponent } from './devices/new-device/new-device.component';
import { EditDeviceComponent } from './devices/edit-device/edit-device.component';
import { NewPatientComponent } from './patients/new-patient/new-patient.component';
import { EditPatientComponent } from './patients/edit-patient/edit-patient.component';
import { ViewPatientComponent } from './patients/view-patient/view-patient.component';
import { NewTestComponent } from './tests/new-test/new-test.component';
import { ViewTestComponent } from './tests/view-test/view-test.component';
import { PreferencesComponent } from './settings/preferences/preferences.component';
import { TestsRecurringComponent } from './tests/tests-recurring/tests-recurring.component';
import { ViewTestRecurringComponent } from './tests/view-test-recurring/view-test-recurring.component';
import { EditTestRecurringComponent } from './tests/edit-test-recurring/edit-test-recurring.component';
import { ContactComponent } from './help/contact/contact.component';
import { MonitorEstermanComponent } from './monitor/monitor-esterman/monitor-esterman.component';
import { MonitorColorComponent } from './monitor/monitor-color/monitor-color.component';
import { MonitorIshiharaComponent } from './monitor/monitor-ishihara/monitor-ishihara.component';
import { MonitorWaggonerComponent } from './monitor/monitor-waggoner/monitor-waggoner.component';
import { BundlesComponent } from './bundles/bundles.component';
import { NewBundleComponent } from './bundles/new-bundle/new-bundle.component';
import { ViewBundleComponent } from './bundles/view-bundle/view-bundle.component';
import { MoveBundleComponent } from './bundles/move-bundle/move-bundle.component';
import { BundlesRecurringComponent } from './bundles/bundles-recurring/bundles-recurring.component';
import { DemoComponent } from './demo/demo/demo.component';
import { MonitorEomsComponent } from './monitor/monitor-eoms/monitor-eoms.component';
import { MonitorPupillometryComponent } from './monitor/monitor-pupillometry/monitor-pupillometry.component';
import { MonitorCoverComponent } from './monitor/monitor-cover/monitor-cover.component';
import { MonitorKineticComponent } from './monitor/monitor-kinetic/monitor-kinetic.component';
import { MonitorCameraComponent } from './monitor/monitor-camera.component/monitor-camera.component';
import { MonitorImageComponent } from './monitor/monitor-image/monitor-image.component';
import { MonitorContrastComponent } from './monitor/monitor-contrast/monitor-contrast.component';
import { MonitorAudiologyComponent } from './monitor/monitor-audiology/monitor-audiology.component';
import { TechallUserComponent } from './techall/techall-user.component';
import { WaitingSessionsComponent } from './techall/waiting-sessions/waiting-sessions.component';
import { FinishedSessionsComponent } from './techall/finished-sessions/finished-sessions.component';
import { MonitorSensorimotorComponent } from './monitor/monitor-sensorimotor/monitor-sensorimotor.component';
import { CustomBundlesComponent } from './custom-bundles/custom-bundles.component';
import { NewCustomBundleComponent } from './custom-bundles/new-custom-bundle/new-custom-bundle.component';
import { ViewCustomBundleComponent } from './custom-bundles/view-custom-bundle/view-custom-bundle.component';
import { ImportVerifyComponent } from './import/verify/import-verify.component';
import { ImportUploadComponent } from './import/upload/import-upload.component';
import { EditAuthenticatorComponent } from './settings/edit-authenticator/edit-authenticator.component';
import { SupportUsersComponent } from './support/support-users/support-users.component';
import { SupportOfficesComponent } from './support/support-offices/support-offices.component';
import { SupportDevicesComponent } from './support/support-devices/support-devices.component';
import { SupportAccountFormComponent } from './support/support-account-form/support-account-form.component';
import { WorkItemsComponent } from './work-items/work-items.component';
import { WorkItemFilterFormComponent } from './work-items/work-item-filter-form/work-item-filter-form.component';
import { WorkItemsHistoryComponent } from './work-items/work-items-history/work-items-history.component';
import { MoveTestComponent } from './tests/move-test/move-test.component';
import { BaaAgreementComponent } from './auth/baa-agreement/baa-agreement.component';
import { ZeroPiiPromptComponent } from './settings/zero-pii-prompt/zero-pii-prompt.component';
import { OfflineControllersComponent } from './offline-controllers/offline-controllers.component';
import { NewOfflineControllerComponent } from './offline-controllers/new-controller/new-controller.component';
import { EditOfflineControllerComponent } from './offline-controllers/edit-controller/edit-controller.component';
import { MergePatientComponent } from './patients/merge-patient/merge-patient.component';
import { SsoOnboardingComponent } from './auth/sso-onboarding/sso-onboarding.component';
import { SsoSuccessComponent } from './auth/sso-success/sso-success.component';
import { SsoErrorComponent } from './auth/sso-error/sso-error.component';
import { InvitationFormComponent } from './invitations/invitation-form/invitation-form.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { DuoRedirectComponent } from './auth/duo-redirect/duo-redirect.component';

// guard
import { AnyLoggedInGuard } from './core/guards/any-logged-in.guard';
import { CustomBundleGuard } from './core/guards/admin.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { OfficeGroupAdminGuard } from './core/guards/office-group-admin.guard';
import { DemoGuard } from './core/guards/demo.guard';
import { TechallUserGuard } from './core/guards/techall-user.guard';
import { TechallAdminGuard } from './core/guards/techall-admin.guard';
import { SupportGuard } from './core/guards/support.guard';
import { PacsGuard } from './core/guards/pacs.guard';
import { OfflineModeGuard } from './core/guards/offline-mode.guard';
import { OfficeAdminGuard } from './core/guards/office-admin.guard';

const appRoutes: Routes = [
  // patients routes
  {
    path: 'patients',
    component: PatientsComponent,
    canActivate: [AuthGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'patients/new',
    component: NewPatientComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard]
  },
  {
    path: 'patients/:id',
    component: ViewPatientComponent,
    canActivate: [AuthGuard, DemoGuard]
  },
  {
    path: 'patients/edit/:id',
    component: EditPatientComponent,
    canActivate: [AuthGuard, DemoGuard]
  },
  {
    path: 'patients/merge/:id',
    component: MergePatientComponent,
    canActivate: [AuthGuard, DemoGuard]
  },
  // tests routes
  {
    path: 'tests/:patientId',
    component: TestsComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'tests/view/:id',
    component: ViewTestComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/new/:patientId',
    component: NewTestComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/move/:id',
    component: MoveTestComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/recurring/:patientId',
    component: TestsRecurringComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'tests/recurring/edit/:id',
    component: EditTestRecurringComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/recurring/view/:id',
    component: ViewTestRecurringComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/bundles/:patientId',
    component: BundlesComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'tests/bundles/new/:patientId',
    component: NewBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/bundles/edit/:id',
    component: ViewBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/bundles/view/:id',
    component: ViewBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/bundles/move/:id',
    component: MoveBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/bundles/recurring/:patientId',
    component: BundlesRecurringComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  // monitor routes
  {
    path: 'tests/observe/:id',
    component: MonitorPerimetryComponent,
    canActivate: [AnyLoggedInGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-landolt-c/:id',
    component: MonitorVisualAcuityComponent,
    canActivate: [AnyLoggedInGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-esterman/:id',
    component: MonitorEstermanComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-color/:id',
    component: MonitorColorComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-ishihara/:id',
    component: MonitorIshiharaComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-waggoner/:id',
    component: MonitorWaggonerComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-eoms/:id',
    component: MonitorEomsComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-pupillometry/:id',
    component: MonitorPupillometryComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-cover/:id',
    component: MonitorCoverComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-sensorimotor/:id',
    component: MonitorSensorimotorComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-kinetic/:id',
    component: MonitorKineticComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-camera/:id',
    component: MonitorCameraComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-image/:id',
    component: MonitorImageComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-contrast/:id',
    component: MonitorContrastComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'tests/observe-audiology/:id',
    component: MonitorAudiologyComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  // import routes
  {
    path: 'import/upload/:patientId',
    component: ImportUploadComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  {
    path: 'import/verify/:uploadId',
    component: ImportVerifyComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, TechallAdminGuard]
  },
  // devices routes
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'devices/new',
    component: NewDeviceComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  {
    path: 'devices/edit/:id',
    component: EditDeviceComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  // offline controller routes
  {
    path: 'offline-controllers',
    component: OfflineControllersComponent,
    canActivate: [AuthGuard, DemoGuard, OfflineModeGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'offline-controllers/new',
    component: NewOfflineControllerComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard, OfflineModeGuard]
  },
  {
    path: 'offline-controllers/edit/:id',
    component: EditOfflineControllerComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard, OfflineModeGuard]
  },
  // custom bundle routes
  {
    path: 'custom-bundles',
    component: CustomBundlesComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, CustomBundleGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'custom-bundles/new',
    component: NewCustomBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, CustomBundleGuard]
  },
  {
    path: 'custom-bundles/view/:id',
    component: ViewCustomBundleComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard, CustomBundleGuard]
  },
  // offices routes
  {
    path: 'offices',
    component: OfficesComponent,
    canActivate: [AuthGuard, OfficeGroupAdminGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'offices/new',
    component: NewOfficeComponent,
    canActivate: [AuthGuard, OfficeGroupAdminGuard, DemoGuard]
  },
  {
    path: 'offices/edit/:id',
    component: EditOfficeComponent,
    canActivate: [AuthGuard, OfficeGroupAdminGuard, DemoGuard]
  },
  {
    path: 'offices/select/:userId',
    component: SelectOfficeComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  // users routes
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'users/new',
    component: NewUserComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  {
    path: 'users/new/:officeId',
    component: NewUserComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  {
    path: 'users/edit/:id',
    component: EditUserComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  {
    path: 'users/select/:officeId',
    component: AssignAdminFormComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  // demo routes
  {
    path: 'demo-mode',
    component: DemoComponent,
    canActivate: [AuthGuard],
    data: { hasNavigation: true }
  },
  // techall routes
  {
    path: 'techall-mode',
    component: TechallUserComponent,
    canActivate: [TechallUserGuard, DemoGuard],
    data: { hasNavigation: true }
  },
  {
    path: 'waiting-sessions',
    component: WaitingSessionsComponent,
    canActivate: [TechallAdminGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'finished-sessions',
    component: FinishedSessionsComponent,
    canActivate: [TechallAdminGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  // settings routes
  {
    path: 'preferences',
    component: PreferencesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'authenticator/edit/:id',
    component: EditAuthenticatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user_settings',
    component: UserSettingsFormComponent,
    canActivate: [DemoGuard]
  },
  // work items routes
  {
    path: 'work-items',
    component: WorkItemsComponent,
    canActivate: [AuthGuard, PacsGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'work-items/filter',
    component: WorkItemFilterFormComponent,
    canActivate: [AuthGuard, PacsGuard]
  },
  {
    path: 'work-items/history',
    component: WorkItemsHistoryComponent,
    canActivate: [AuthGuard, PacsGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  // support routes
  {
    path: 'support/users',
    component: SupportUsersComponent,
    canActivate: [AuthGuard, SupportGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'support/offices',
    component: SupportOfficesComponent,
    canActivate: [AuthGuard, SupportGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'support/devices',
    component: SupportDevicesComponent,
    canActivate: [AuthGuard, SupportGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'support/accounts/new',
    component: SupportAccountFormComponent,
    canActivate: [AuthGuard, SupportGuard]
  },
  // auth routes
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot',
    component: ResetPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'invitations',
    component: InvitationsComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'invitations/new',
    component: InvitationFormComponent,
    canActivate: [AuthGuard, OfficeAdminGuard, DemoGuard]
  },
  {
    path: 'sso/onboarding',
    component: SsoOnboardingComponent
  },
  {
    path: 'sso/authorize',
    component: SsoOnboardingComponent
  },
  {
    path: 'sso/success',
    component: SsoSuccessComponent
  },
  {
    path: 'sso/error',
    component: SsoErrorComponent
  },
  {
    path: 'duo/redirect',
    component: DuoRedirectComponent
  },

  // other routes
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [DemoGuard]
  },
  {
    path: 'zero-pii-prompt',
    component: ZeroPiiPromptComponent,
    canActivate: [AnyLoggedInGuard, DemoGuard]
  },
  {
    path: 'baa-agreement',
    component: BaaAgreementComponent,
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [AnyLoggedInGuard],
    data: { hasNavigation: true, hasFooter: true }
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AnyLoggedInGuard]
  },
  {
    path: '',
    redirectTo: 'patients',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
