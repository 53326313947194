// native
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { delay, map, retryWhen, take } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';

// service
import { ApiService } from './api.service';

// models
import {
  ActiveDevice, AcuityOptotypeRecord, AudiologyRecord, ColorTestState, ContrastRecord, CoverState, EomsRecord, IshiharaState, MonitorEvent,
  PerimetryState, PlotData, PupillometryState, SensorimotorState, Test, TestBundle, TestGroupInfo, TestListItem, TestStrategy, WaggonerState
} from '../../models';

// constants
import {
  API_COVER_STATE_PATH, API_DEVICES_PATH, API_EOMS_STATE_PATH, API_ISHIHARA_STATE_PATH, API_PERIMETRY_STATE_PATH, API_PUPILLOMETRY_STATE_PATH,
  API_TESTS_PATH, API_ACUITY_STATE_PATH, GROUP, STRATEGY, WEBSOCKET_MONITOR_TESTS, API_SENSORIMOTOR_STATE_PATH, API_COLOR_VISION_STATE_PATH, API_WAGGONER_STATE_PATH,
  API_CONTRAST_STANDARD_STATE_PATH, API_AUDIOLOGY_STATE_PATH
} from '../../constants';

interface PlotDimensions {
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root'
})
export class MonitorTestService {
  getVisualTestLayout(strategy: TestStrategy, isLarge: boolean = false) {
    if (strategy?.value === STRATEGY.ESTERMAN)
      return this.estermanLayout;

    const range = isLarge ? [-7.0, 7.0] : [-5.0, 5.0];

    return {
      paper_bgcolor: 'transparent',
      plot_bgcolor: '#0b1935',
      margin: {
        l: 0,
        r: 0,
        t: 0,
        b: 0
      },
      xaxis: {
        range,
        dtick: 1,
        type: 'linear',
        fixedrange: true,
        gridcolor: 'transparent',
        color: '#D3D3D3'
      },
      yaxis: {
        range,
        dtick: 1,
        type: 'linear',
        fixedrange: true,
        gridcolor: 'transparent',
        color: '#D3D3D3'
      },
      title: ''
    };
  }

  /**
   * 'color' is an arbitrary array of numbers, it can have any minimum and maximum,
   * except if cmin/cmax are set, which cap each input to within that range.
   *
   * Example: if I have 1,2,3 and cmax is 2, it really reads 1,2,2.
   *
   * Colorscale has nothing to do with the actual values
   * in the array. It's always on a scale of 0-1. So if you want three discrete colors,
   * instead of [0, 1, 2], you need to use [0, 0.5, 1].
   */

  visualTestInitialPlotData: PlotData = {
    x: [],
    y: [],
    marker: {
      color: [],
      symbol: 'circle',
      opacity: 1,
      cmin: 0,
      cmax: 2,
      colorscale: [
        // Not seen, red
        [0, 'rgb(244,80,66)'],
        // Seen, but not final, grey
        [0.5, 'rgb(180,180,180)'],
        // Final, seen or unseen, green
        [1, 'rgb(0,212,0)']
      ]
    },
    mode: 'markers+text',
    visible: true,
    type: 'scatter',
    text: [],
    textposition: 'top center',
    textfont: {
      family: 'Arial',
      color: '#D3D3D3'
    }
  };

  estermanLayout = {
    paper_bgcolor: 'transparent',
    plot_bgcolor: '#0b1935',
    margin: {
      l: 0,
      r: 0,
      t: 0,
      b: 0
    },
    xaxis: {
      range: [-15.0, 15.0],
      dtick: 1,
      type: 'linear',
      fixedrange: true,
      gridcolor: 'transparent',
      color: '#D3D3D3'
    },
    yaxis: {
      range: [-15.0, 15.0],
      dtick: 1,
      type: 'linear',
      fixedrange: true,
      gridcolor: 'transparent',
      color: '#D3D3D3'
    },
    title: ''
  };

  eomsLayout = {
    paper_bgcolor: 'transparent',
    plot_bgcolor: '#0b1935',
    margin: {
      l: 0,
      r: 0,
      t: 0,
      b: 0
    },
    xaxis: {
      range: [-1.2, 1.2],
      dtick: 1,
      type: 'linear',
      fixedrange: true,
      gridcolor: 'transparent',
      color: 'transparent'
    },
    yaxis: {
      range: [-1.2, 1.2],
      dtick: 1,
      type: 'linear',
      fixedrange: true,
      gridcolor: 'transparent',
      color: 'transparent'
    },
    title: ''
  };

  getKineticLayout(isLarge: boolean = false) {
    const range = isLarge ? [-12.0, 12.0] : [-8.0, 8.0];

    return {
      paper_bgcolor: 'transparent',
      plot_bgcolor: '#0b1935',
      margin: {
        l: 0,
        r: 0,
        t: 0,
        b: 0
      },
      xaxis: {
        range,
        dtick: 1,
        type: 'linear',
        fixedrange: true,
        gridcolor: 'transparent',
        color: 'grey'
      },
      yaxis: {
        range,
        dtick: 1,
        type: 'linear',
        fixedrange: true,
        gridcolor: 'transparent',
        color: 'grey'
      },
      title: ''
    };
  }

  public constructor(
    private router: Router,
    private apiService: ApiService
  ) { }

  openMonitorScreen(entity: Partial<TestListItem> | ActiveDevice, reloadScreen: boolean = false, navExtras: NavigationExtras = null) {
    if (!entity)
      return;

    const url = this.getUrl(entity.test_group);

    if (navExtras)
      return this.router.navigate([url, (<ActiveDevice>entity).test], navExtras);

    if (reloadScreen)
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([url, (<Partial<Test>>entity).id]);
      });
    else
      this.router.navigate([url, (<Partial<Test>>entity).id]);
  }

  private getUrl(testGroup: TestGroupInfo) {
    if ([GROUP.VISUAL_ACUITY].includes(testGroup.group?.value) || (STRATEGY.CONTRAST_SINGLE_OPTOTYPE === testGroup.strategy?.value))
      return 'tests/observe-landolt-c';
    if ((GROUP.EOMS === testGroup.group?.value) || (STRATEGY.EOMS_SENSORIMOTOR === testGroup.strategy?.value))
      return 'tests/observe-eoms';
    if (GROUP.PUPIL === testGroup.group?.value)
      return 'tests/observe-pupillometry';
    if ((GROUP.COVER === testGroup.group?.value) || (STRATEGY.COVER_SENSORIMOTOR === testGroup.strategy?.value))
      return 'tests/observe-cover';
    if ([GROUP.SENSORIMOTOR_SHAPES, GROUP.SENSORIMOTOR_CARDINAL].includes(testGroup.group?.value))
      return 'tests/observe-sensorimotor';
    if (GROUP.ANTERIOR_SEGMENT_IMAGE === testGroup.group?.value)
      return 'tests/observe-camera';
    if ([GROUP.ONGOING_EYE_IMAGE_CAPTURE, GROUP.EYE_IMAGE_CAPTURE].includes(testGroup.group?.value))
      return 'tests/observe-image';
    if (GROUP.AUDIOLOGY === testGroup.group?.value)
      return 'tests/observe-audiology';
    if (STRATEGY.ESTERMAN === testGroup.strategy?.value)
      return 'tests/observe-esterman';
    if (STRATEGY.KINETIC === testGroup.strategy?.value)
      return 'tests/observe-kinetic';
    if (STRATEGY.D15 === testGroup.strategy?.value)
      return 'tests/observe-color';
    if (STRATEGY.ISHIHARA === testGroup.strategy?.value)
      return 'tests/observe-ishihara';
    if ([STRATEGY.WAGGONER, STRATEGY.WAGGONER_FOR_SENIORS, STRATEGY.WAGGONER_FOR_OLDER_CHILDREN].includes(testGroup.strategy?.value))
      return 'tests/observe-waggoner';
    if ([STRATEGY.CONTRAST_STANDARD].includes(testGroup.strategy?.value))
      return 'tests/observe-contrast';

    return 'tests/observe';
  }

  sendActionToDevice(id: number, body: { message: string; payload?: string }): Observable<any> {
    return this.apiService.put(`${API_DEVICES_PATH}${id}/message/`, body).pipe(map(obj => obj as any));
  }

  restartTest(id: number): Observable<Test> {
    return this.apiService.put(`${API_TESTS_PATH}${id}/restart/`).pipe(map(res => res as Test));
  }

  getProgress(id: number): Observable<number> {
    return this.apiService.get(`${API_TESTS_PATH}${id}/progress/`).pipe(map(res => res['total_progress']));
  }

  getPerimetryState(id: number, isThreshold: boolean): Observable<PerimetryState> {
    return this.apiService.get(`${API_PERIMETRY_STATE_PATH}?test=${id}&threshold=${isThreshold}`).pipe(map(obj => obj as PerimetryState));
  }

  getAcuityState(id: number) {
    return this.apiService.get(`${API_ACUITY_STATE_PATH}?test=${id}`).pipe(map(res => res as AcuityOptotypeRecord[]));;
  }

  getColorState(id: number): Observable<ColorTestState> {
    return this.apiService.get(`${API_COLOR_VISION_STATE_PATH}?test=${id}`).pipe(map(res => res as ColorTestState));
  }

  getIshiharaState(id: number): Observable<IshiharaState> {
    return this.apiService.get(`${API_ISHIHARA_STATE_PATH}?test=${id}`).pipe(map(res => res as IshiharaState));
  }

  getWaggonerState(id: number): Observable<WaggonerState> {
    return this.apiService.get(`${API_WAGGONER_STATE_PATH}?test=${id}`).pipe(map(res => res as WaggonerState));
  }

  getEomsTestRecords(id: number): Observable<EomsRecord[]> {
    return this.apiService.get(`${API_EOMS_STATE_PATH}?test=${id}`).pipe(map(res => res as EomsRecord[]));
  }

  getPupillometryState(id: number): Observable<PupillometryState> {
    return this.apiService.get(`${API_PUPILLOMETRY_STATE_PATH}?test=${id}`).pipe(map(res => res as PupillometryState));
  }

  getCoverState(id: number): Observable<CoverState> {
    return this.apiService.get(`${API_COVER_STATE_PATH}?test=${id}`).pipe(map(res => res as CoverState));
  }

  getSensorimotorState(id: number): Observable<SensorimotorState> {
    return this.apiService.get(`${API_SENSORIMOTOR_STATE_PATH}?test=${id}`).pipe(map(res => res as SensorimotorState));
  }

  getContrastStandardThresholdState(id: number): Observable<ContrastRecord[]> {
    return this.apiService.get(`${API_CONTRAST_STANDARD_STATE_PATH}?test=${id}&threshold=true`)
      .pipe(map((res: any) => res.results as ContrastRecord[]));
  }

  getContrastStandardOngoingState(id: number): Observable<ContrastRecord> {
    return this.apiService.get(`${API_CONTRAST_STANDARD_STATE_PATH}?test=${id}&limit=1`)
      .pipe(map((res: any) => res.results[0] as ContrastRecord));
  }

  getAudiologyState(id: number) {
    return this.apiService.get(`${API_AUDIOLOGY_STATE_PATH}?test=${id}`).pipe(map(res => res as AudiologyRecord[]));
  }

  listenUpdates(testId): Observable<MonitorEvent> {
    const protocol = isDevMode() ? 'ws' : 'wss';
    const relativeUrl = `${WEBSOCKET_MONITOR_TESTS}${testId}`;

    return webSocket(`${protocol}://${window.location.host}${relativeUrl}`).pipe(
      map(obj => obj as MonitorEvent),
      retryWhen((errors) => errors.pipe(
        delay(5000),
        take(10)
      ))
    );
  }

  coverSensorimotorXCoords = [0, 0.00000, 0.67344, 0.95238, 0.67344, 0.00000, -0.67344, -0.95238, -0.67344];
  coverSensorimotorYCoords = [0, 0.95238, 0.67344, 0.00000, -0.67344, -0.95238, -0.67344, 0.00000, 0.67344];

  getEomsInitialPlotData(): PlotData {
    return {
      x: [0],
      y: [0],
      marker: {
        color: [1, 1, 1, 1, 1, 1, 1, 1, 1],
        symbol: 'circle',
        opacity: 1,
        cmin: 0,
        cmax: 2,
        colorscale: [
          [0, 'rgb(244,80,66)'],
          // orange marks successful, but repeated test for the same position
          [0.25, 'rgb(255,215,0)'],
          [0.5, 'rgb(180,180,180)'],
          // previous targets are light blue
          [0.75, 'rgb(0,255,255)'],
          [1, 'rgb(0,212,0)']
        ]
      },
      mode: 'markers+text',
      visible: true,
      type: 'scatter',
      text: [],
      textposition: 'top center',
      textfont: {
        family: 'Arial',
        color: '#D3D3D3'
      }
    };
  }

  getKineticInitialPlotData(): PlotData {
    return {
      x: [],
      y: [],
      marker: {
        color: [],
        symbol: 'circle',
        opacity: 1,
        cmin: 0,
        cmax: 2,
        colorscale: [
          [0, 'rgb(244,80,66)'],
          [0.5, 'rgb(180,180,180)'],
          [1, 'rgb(0,212,0)']
        ]
      },
      line: {
        color: 'rgb(240, 240, 240)',
        width: 1,
        shape: 'spline'
      },
      mode: 'markers+lines+text',
      connectgaps: true,
      visible: true,
      type: 'scatter',
      text: [],
      textposition: 'top center',
      textfont: {
        family: 'Arial',
        color: '#D3D3D3'
      }
    };
  }

  getSensorimotorInitialPlotData(): PlotData {
    let x = [0], y = [0];

    return {
      x,
      y,
      marker: {
        color: [1],
        symbol: 'circle',
        opacity: 1,
        cmin: 0,
        cmax: 2,
        colorscale: [
          // non-threshold current source is yellow
          [0, 'rgb(255,255,0)'],
          // previous targets are light blue
          [0.25, 'rgb(0,255,255)'],
          // initial central marker or current source wihtout distance is grey
          [0.5, 'rgb(180,180,180)'],
          // current target is red
          [0.75, 'rgb(255,0,0)'],
          // threshold current source is green
          [1, 'rgb(0,212,0)']
        ]
      },
      mode: 'markers+text',
      visible: true,
      type: 'scatter',
      text: [],
      textposition: 'top center',
      textfont: {
        family: 'Arial',
        color: '#D3D3D3'
      }
    };
  }

  public plotOptions = {
    staticPlot: true,
    scrollZoom: false
  };

  getDoublePlotDimensions(monitorContainerEl: HTMLElement, plotContainerEl: HTMLElement): PlotDimensions {
    const containerWidth = monitorContainerEl.clientWidth;
    const containerHeight = monitorContainerEl.clientHeight;

    const plotContainerWidth = plotContainerEl.clientWidth;
    const plotContainerHeight = plotContainerEl.clientHeight;

    let ratio = 0.85;

    if ((containerWidth > containerHeight) && containerWidth < 900)
      ratio = 0.75;

    if (containerWidth < 400)
      ratio = 0.7;

    let size = Math.min(plotContainerWidth, plotContainerHeight) * ratio;

    return { width: size, height: size };
  }

  getSinglePlotDimensions(plotContainerEl: HTMLElement): PlotDimensions {
    const containerWidth: number = plotContainerEl.clientWidth;
    const containerHeight: number = plotContainerEl.clientHeight;
    const size = Math.min(containerWidth, containerHeight) * 0.9;

    return { width: size, height: size };
  }

  getDisplayTitle(group: TestGroupInfo): string {
    if (!group)
      return '';

    if (group?.strategy?.value === STRATEGY.EOMS_SENSORIMOTOR)
      return `${group.group.name} (${group.strategy.name})`;

    if (group?.strategy?.value === STRATEGY.PURE_TONE)
      return `${group.group.name} (${group.strategy.name})`;

    if (group?.strategy?.value === STRATEGY.COVER_SENSORIMOTOR)
      return `${group.group.name} (${group.protocol.name})`;

    if (group?.strategy?.value)
      return group.strategy.name;

    if (group?.protocol?.value && group?.group?.value)
      return `${group.group.name} (${group.protocol.name})`;

    if (group?.group?.value)
      return group?.group?.name;
  }

  getBundleInfo(test: Test, bundle: TestBundle): string {
    if (!test || !bundle)
      return '';

    let text = '';

    if (test?.test_group?.group?.value)
      text = text + test?.test_group?.group?.name;

    if (test?.test_group?.strategy?.value)
      text = text + `: ${test?.test_group?.strategy?.name}`;

    if (test?.test_group?.protocol?.value)
      text = text + `: ${test?.test_group?.protocol?.name}`;

    text = text + ` (${test.test_bundle_index} of ${bundle.total_test_count})`;
    return text;
  }

  getFalseNegativePercentage(spotsCount: number): number {
    const cap = 4;
    if (spotsCount >= cap)
      return 100;
    if (spotsCount < 0)
      return 0;
    return (spotsCount / cap) * 100;
  }

  getFixationLossPercentage(spotsCount: number): number {
    const cap = 4;
    if (spotsCount >= cap)
      return 100;
    if (spotsCount < 0)
      return 0;
    return (spotsCount / cap) * 100;
  }
}