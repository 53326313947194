<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--limit-height">
    <div
      *ngIf="isOsEyeShown" 
      class="monitor_graph-box"
      [ngClass]="{ 'monitor_graph-box--single' : isSingleEyeTest }">
      <div *ngIf="isTestSyncing || (osPhaseSyncing && !isTestDone)" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div *ngIf="osPhaseSkipped" class="monitor_sync-box"><div class="monitor_overlay-text monitor_overlay-text--small">{{'skippedMessage' | translate}}</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-skip-button 
        class="monitor_graph-icon" 
        [visible]="osPhaseSkippable" 
        (onSkip)="onSkipEye('OS')">
      </app-skip-button>
      <br><br>
      <div @startList class="monitor_list-container">
        <div
          *ngFor="let record of osData.slice().reverse(), index as i" 
          @fadeAnimation 
          class="monitor_list-item"
          [ngClass]="{'hidden': isOsRecordHidden(record, i)}">
          <ng-container *ngIf="record, else noRecordTemplate">
            <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
          </ng-container>
        </div>
        <small *ngIf="!isLoading && !osData?.length">{{ isTestDone ? ('noTestDataReceived' | translate) : ('noTestData' | translate) }}</small>
      </div>
    </div>
    <div 
      *ngIf="isOdEyeShown" 
      class="monitor_graph-box"
      [ngClass]="{ 'monitor_graph-box--single' : isSingleEyeTest }">
      <div *ngIf="isTestSyncing || (odPhaseSyncing && !isTestDone)" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div *ngIf="odPhaseSkipped" class="monitor_sync-box"><div class="monitor_overlay-text monitor_overlay-text--small">{{'skippedMessage' | translate}}</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-skip-button 
        class="monitor_graph-icon" 
        [visible]="odPhaseSkippable" 
        (onSkip)="onSkipEye('OD')">
      </app-skip-button>
      <div @startList class="monitor_list-container">
        <div 
          *ngFor="let record of odData.slice().reverse(), index as i"
          @fadeAnimation 
          class="monitor_list-item"
          [ngClass]="{'hidden': record && record.threshold && record.seq_num}">
          <ng-container *ngIf="record, else noRecordTemplate">
            <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
          </ng-container>
        </div>
        <small *ngIf="!isLoading && !odData?.length">{{ isTestDone ? ('noTestDataReceived' | translate) : ('noTestData' | translate) }}</small>
      </div>
    </div>
  </div>

  <div class="monitor_container-column monitor_container-column--limit-height">
    <div 
      *ngIf="!isInsideCSuite && !isSingleEyeTest"
      class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div *ngIf="ouPhaseSkipped" class="monitor_sync-box"><div class="monitor_overlay-text monitor_overlay-text--small">{{'skippedMessage' | translate}}</div></div>
      <div class="monitor_graph-title">{{ 'both' | translate | titlecase }}</div>
      <app-skip-button 
        class="monitor_graph-icon" 
        [visible]="ouPhaseSkippable" 
        (onSkip)="onSkipEye(null)">
      </app-skip-button>
      <div @startList class="monitor_list-container">
        <div 
          *ngFor="let record of ouData.slice().reverse(), index as i" 
          @fadeAnimation 
          class="monitor_list-item"
          [ngClass]="{'hidden': isOuRecordHidden(record, i)}">
          <ng-container *ngIf="record, else noRecordTemplate">
            <ng-container *ngTemplateOutlet="recordView; context: { record: record }"></ng-container>
          </ng-container>
        </div>
        <small *ngIf="!isLoading && !ouData?.length">{{ isTestDone ? ('noTestDataReceived' | translate) : ('noTestData' | translate) }}</small>
      </div>
    </div>
    <div 
      class="monitor_graph-box"
      [ngClass]="{ 'monitor_graph-box--single' : isInsideCSuite || isSingleEyeTest }">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone()">
      </app-percentage-circle>
    </div>
  </div>
</div>

<ng-template #recordView let-record="record">
  <i class="fas fa-eye" [ngClass]="{ 'orange': !record?.viewed, 'color-primary': record?.viewed }"></i>
  <span 
    matTooltip="{{ isContrastTest ? ('contrast' | translate | titlecase) : ('size' | translate | titlecase) }}" 
    class="pointer">
    {{ isContrastTest ? (record?.contrast + '&#37;') : ((record?.version > 1) ? record?.size_label : record?.size) }}
  </span>

  <app-direction-arrow   
    *ngIf="record?.direction"
    matTooltip="{{ 'direction' | translate | titlecase }}"
    class="monitor_list-arrow" 
    [direction]="record?.direction" 
    [viewed]="record?.viewed">
  </app-direction-arrow>

  <span>
    {{ record?.viewed ? ('seen' | translate) : ('unseen' | translate) }}
  </span>
</ng-template>

<ng-template #noRecordTemplate>
  <span class="text--blink">{{'syncing' | translate | titlecase}}...</span>
</ng-template>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>

