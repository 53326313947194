// native
import { Component, ChangeDetectorRef, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from '../../core/services/auth.service';
import { WebAuthnService } from '../../core/services/webauthn.service';
import { ErrorService } from '../../core/services/error.service';
import { SsoService } from '../../core/services/sso.service';

// models
import { DuoAuthResponse, UserLoginRequest, CenterContainerTitle, WebAuthnResponse } from '../../models';

// animation
import { fadeTransition } from '../../animations/router-animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: [fadeTransition()],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  title: CenterContainerTitle = { thin: 'visu', bold: 'ALL' };

  passwordVisible: boolean = false;
  forceMinimizedLabel: boolean = false;

  @ViewChild('usernameInput') usernameInput: ElementRef;
  @ViewChild('passwordInput') passwordInput: ElementRef;

  ssoDomain: string;

  constructor(
    public authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private webAuthnService: WebAuthnService,
    private errorService: ErrorService,
    private ssoService: SsoService
  ) { }

  ngOnInit() {
    this.initForm();

    this.authService.clearLocalData();

    if (!this.authService.ssoDomain) {
      this.checkChromeAutofill();
    }
  }

  initForm() {
    this.form = this.formBuilder.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });
  }

  onSubmit() {
    const username = this.form.value.username.trim();
    const password = this.form.value.password.trim();

    const body: UserLoginRequest = { username, password };

    this.authService.login(body).subscribe(res => {

      if ((<DuoAuthResponse>res).auth_url) {
        window.location.href = (<DuoAuthResponse>res).auth_url;
        return;
      }

      if ((<WebAuthnResponse>res).method) {
        this.handleWebAuthnLogin();
        return;
      }

      this.authService.handleLoginSuccess();

    }, ({ error }) => {
      if (error?.error === 'UserLockedOut')
        return this.errorService.handleError(error?.message);

      this.errorService.handleError(this.translateService.instant('invalidLogin'));
    });
  }

  private handleWebAuthnLogin() {
    this.authService.loading$.next(true);
    this.webAuthnService.getCredentials().then(credentials => {
      this.authService.verifyWebAuthnLogin(credentials).subscribe(res => {
        this.authService.handleLoginSuccess();
      }, error => this.errorService.handleError(this.translateService.instant('invalidLogin')));
    }).catch(error => {
      this.authService.loading$.next(false);
      this.errorService.handleError(this.translateService.instant('invalidLogin'));
    });
  }

  private checkChromeAutofill() {
    setTimeout(() => {
      const usernameInputComputedStyle = window.getComputedStyle(this.usernameInput.nativeElement, null);
      if (usernameInputComputedStyle?.getPropertyValue('appearance') === 'menulist-button') {
        this.form.controls.username.setErrors(null);
        this.form.controls.password.setErrors(null);
        this.forceMinimizedLabel = true;
      }
    }, 500);
  }

  onSsoLogin() {
    this.authService.loading$.next(true);
    this.ssoService.ssoLogin(this.authService.ssoDomain);
  }
}
