// native
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from '../../core/services/auth.service';
import { ErrorService } from '../../core/services/error.service';
import { WebAuthnService } from '../../core/services/webauthn.service';

// models
import { DuoVerifyRequest, WebAuthnResponse } from '../../models';

@Component({
  selector: 'app-duo-redirect',
  templateUrl: './duo-redirect.component.html'
})
export class DuoRedirectComponent {

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService,
    private webAuthnService: WebAuthnService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const duoState = params['state'];
      const duoCode = params['duo_code'];

      if (!!duoState && !!duoCode)
        this.verifyDuoLogin(duoState, duoCode);
    });
  }

  private verifyDuoLogin(duoState: string, duoCode: string) {
    const body: DuoVerifyRequest = {
      duo_state: duoState,
      duo_code: duoCode
    };

    this.authService.verifyDuoLogin(body).subscribe(res => {
      if ((<WebAuthnResponse>res).method) {
        this.handleWebAuthnLogin();
        return;
      }
      this.authService.handleLoginSuccess();
    }, err => {
      this.errorService.handleError(err?.error);
    });
  }

  private handleWebAuthnLogin() {
    this.webAuthnService.getCredentials().then(credentials => {
      this.authService.verifyWebAuthnLogin(credentials).subscribe(res => {
        this.authService.handleLoginSuccess();
      }, error => this.errorService.handleError(this.translateService.instant('invalidLogin')));
    }).catch(error => {
      this.errorService.handleError(this.translateService.instant('invalidLogin'));
    });
  }
}
