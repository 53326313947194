<app-center-container
  [title]="title"
  [showClose]="true"
  closeTooltip="{{ 'exit' | translate | titlecase }}"
  (close)="closeForm()">

  <form *ngIf="form" [formGroup]="form">

    <app-input-wrapper 
      [hidden]="currentBundleStep !== 1 || newTestPromptShown"
      [control]="form.get('name')" 
      label="{{ 'bundleName' | translate }}"
      identifier="name">
      <input class="form_control" type="text" formControlName="name" name="name" id="name">
    </app-input-wrapper>

    <br>

    <div class="slider_container">
      <span 
        class="slider_icon"
        [ngClass]="{ 'slider_icon--disabled': isBackDisabled() }"
        matTooltip="{{ 'navigateBundleBack' | translate | titlecase }}"
        (click)="onBack()">
        <i class="fas fa-chevron-left"></i>
      </span>
      <span class="slider_title">{{ 'test' | translate }} #&nbsp;{{ currentBundleStep }}</span>
      <span 
        class="slider_icon"
        [ngClass]="{ 'slider_icon--disabled': isForwardDisabled() }"
        matTooltip="{{ 'navigateBundleForward' | translate | titlecase }}"
        (click)="onForward()">
        <i class="fas fa-chevron-right"></i>
      </span>
    </div>

    <ng-container *ngIf="!newTestPromptShown, else newTestTemplate">

      <app-input-wrapper 
        [control]="form.get('group')" 
        label="{{ 'testGroup' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="group" 
          disableOptionCentering 
          panelClass="dropdown"
          name="group"
          aria-label="{{ 'testGroup' | translate }}">
          <mat-option *ngFor="let testGroup of displayedTestGroups" [value]="testGroup.value">
            <i *ngIf="testGroup.value === 'CSUITE'" class="fa fa-layer-group"></i>
            <span>{{ testGroup.name }}</span>
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="displayedTestStrategies?.length > 1" 
        [control]="form.get('strategy')" 
        label="{{ 'testStrategy' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="strategy" 
          disableOptionCentering 
          panelClass="dropdown"
          name="strategy"
          aria-label="{{ 'testStrategy' | translate }}">
          <mat-option 
            *ngFor="let testStrategy of displayedTestStrategies" 
            [value]="testStrategy.value"
            [ngClass]="{'hidden--force': testStrategy.value === 'BLUE_PUPILLOMETRY'}">
            {{ testStrategy.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="displayedTestProtocols?.length > 1" 
        [control]="form.get('protocol')" 
        label="{{ 'testProtocol' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="protocol" 
          disableOptionCentering 
          panelClass="dropdown"
          name="protocol"
          aria-label="{{ 'testProtocol' | translate }}">
          <mat-option 
            *ngFor="let testProtocol of displayedTestProtocols" 
            [value]="testProtocol.value"
            [ngClass]="{'hidden--force': testProtocol.value === 'QUADRANT'}">
            {{ testProtocol.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithEyeSelection(selectedTestGroup, selectedTestStrategy, null, selectedMonocular, true)"
        [control]="form.get('eye')" 
        label="{{ testFormService.isEyeMovableTest(selectedTestGroup) ? ('eyeMovable' | translate) : ('eyes' | translate) }}">

        <div class="radio_group">
          <ng-container *ngIf="!testFormService.isSingleEyeTest(selectedTestGroup, selectedTestStrategy, null, true)">
            <input 
              id="eyes-both"
              class="radio_input" 
              type="radio" 
              formControlName="eye"
              (change)="onEyeChange()"
              value="both">
            <label class="radio_label" for="eyes-both">
              <div class="radio_button"></div>
              <div class="radio_text">{{ 'both' | translate }}</div>
            </label>
          </ng-container>

          <input 
            id="eyes-od"
            class="radio_input" 
            type="radio" 
            formControlName="eye"
            (change)="onEyeChange()"
            value="OD">
          <label class="radio_label" for="eyes-od">
            <div class="radio_button"></div>
            <div class="radio_text">{{ 'right' | translate | titlecase }}</div>
          </label>

          <input 
            id="eyes-os"
            class="radio_input" 
            type="radio" 
            formControlName="eye"
            (change)="onEyeChange()"
            value="OS">
          <label class="radio_label" for="eyes-os">
            <div class="radio_button"></div>
            <div class="radio_text">{{ 'left' | translate | titlecase }}</div>
          </label>
        </div>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithEarSelection(selectedTestGroup)"
        [control]="form.get('ear')" 
        label="{{ 'startEar' | translate }}">

        <div class="radio_group">
          <input 
            id="ears-right"
            class="radio_input" 
            type="radio" 
            formControlName="ear"
            value="RIGHT">
          <label class="radio_label" for="ears-right">
            <div class="radio_button"></div>
            <div class="radio_text">{{ 'right' | translate | titlecase }}</div>
          </label>

          <input 
            id="ears-left"
            class="radio_input" 
            type="radio" 
            formControlName="ear"
            value="LEFT">
          <label class="radio_label" for="ears-left">
            <div class="radio_button"></div>
            <div class="radio_text">{{ 'left' | translate | titlecase }}</div>
          </label>
        </div>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithNonConvergenceMonocular(selectedTestGroup, selectedTestStrategy, selectedEye, null, true)"
        [control]="form.get('monocular')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="monocular"
          name="monocular-checkbox"
          (change)="onMonocularCheckboxChange()"
          id="monocular">
        <label 
          for="monocular"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('monocular').errors && form.get('monocular').touched }">
          {{ 'monocular' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isMonocularColorCheckboxShown(selectedTestGroup)"
        [control]="form.get('monocular_color')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="monocular_color"
          name="monocular-color"
          id="monocular-color">
        <label 
          for="monocular-color"
          class="checkbox_label">
          {{ 'monocularColor' | translate | titlecase }}
        </label>
        <br>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithCorrection(selectedTestGroup, selectedTestStrategy, test)"
        [control]="form.get('with_correction')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="with_correction"
          id="with_correction"
          name="with_correction">
        <label 
          for="with_correction"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('with_correction').errors && form.get('with_correction').touched }">
          {{ 'withCorrection' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithCorrectionType(selectedTestGroup, test)"
        [control]="form.get('correction_type')"
        label="{{ 'correctionType' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="correction_type" 
          disableOptionCentering 
          panelClass="dropdown"
          name="correction_type"
          aria-label="{{ 'correctionType' | translate }}">
          <mat-option [value]="'none'">{{ 'none' | translate }}</mat-option>
          <mat-option *ngFor="let type of correctionTypes" [value]="type.id">{{ type.name?.toLowerCase() | translate | titlecase }}</mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithIntensity(selectedTestStrategy, selectedTestProtocol, null, true)"
        [control]="form.get('supra_t_intensity')" 
        label="{{ testFormService.isTestWithSupraIntensity(selectedTestStrategy) ? ('supraIntensity' | translate) : ('estermanIntensity' | translate) }}">
        <mat-select 
          class="form_control"
          name="supra_t_intensity"
          formControlName="supra_t_intensity" 
          disableOptionCentering 
          panelClass="dropdown"
          aria-label="{{ testFormService.isTestWithSupraIntensity(selectedTestStrategy) ? ('supraIntensity' | translate) : ('estermanIntensity' | translate) }}">
          <mat-option *ngFor="let intensity of supraTIntensities" [value]="intensity.value">
            {{ intensity.label | translate | titlecase }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isGoldmanSizeDisplayed(selectedTestStrategy)"
        [control]="form.get('goldman_size')" 
        label="{{ 'goldmanSize' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="goldman_size" 
          disableOptionCentering 
          panelClass="dropdown"
          name="goldman_size"
          aria-label="{{ 'goldmanSize' | translate }}">
          <mat-option
            *ngFor="let goldmanSize of selectedTestStrategy.goldman_size"
            [value]="goldmanSize">
            {{ testFormService.getGoldmanSizeLabel(goldmanSize) }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithStimulusType(selectedTestStrategy)"
        [control]="form.get('stimulus_type')" 
        label="{{ 'stimulusType' | translate }}">
        <mat-select 
          class="form_control"
          name="stimulus_type"
          formControlName="stimulus_type" 
          disableOptionCentering 
          panelClass="dropdown"
          aria-label="{{ 'stimulusType' | translate }}">
          <mat-option *ngFor="let stimulusType of stimulusTypes" [value]="stimulusType">
            {{ stimulusType }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithApdStimulationTime(selectedTestStrategy)"
        [control]="form.get('apd_stimulation_time')" 
        label="{{ 'apdStimulationTime' | translate }}">
        <mat-select 
          class="form_control"
          name="apd_stimulation_time"
          formControlName="apd_stimulation_time"
          disableOptionCentering 
          panelClass="dropdown"
          aria-label="{{ 'apdStimulationTime' | translate }}">
          <mat-option *ngFor="let stimulationTime of apdStimulationTimes" [value]="stimulationTime">
            {{ stimulationTime }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <ng-container *ngIf="testFormService.isTestWithControlType(selectedTestGroup, selectedTestStrategy, selectedTestProtocol)">
        <app-info-box 
          *ngIf="isVoiceWarningDisplayed && form.get('control_type')?.value && (selectedTestGroup?.value !== 'COLOR_VISION')"
          message="{{ 'voiceSelectionMessage' | translate }}"
          (onClose)="isVoiceWarningDisplayed = false"> 
        </app-info-box>

        <app-input-wrapper
          [control]="form.get('control_type')" 
          label="{{ 'controlType' | translate }}">
          <mat-select 
            class="form_control"
            name="control_type"
            aria-label="{{ 'controlType' | translate }}"
            formControlName="control_type" 
            disableOptionCentering 
            panelClass="dropdown"
            (selectionChange)="onControlTypeChange($event.value)">
            <mat-option *ngFor="let type of controlTypes" [value]="type.id">
              {{ type.name?.toLowerCase() | translate | titlecase }}
            </mat-option>
          </mat-select>
        </app-input-wrapper>
      </ng-container>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithOcclusionTime(selectedTestGroup, selectedTestStrategy)" 
        [control]="form.get('occlusion_time')" 
        label="{{ 'occlusionTime' | translate }}">
        <input 
          name="occlusion_time"
          class="form_control" 
          type="number" 
          min="1" 
          max="10" 
          formControlName="occlusion_time">
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithPositions(selectedTestStrategy)"
        [control]="form.get('cover_directions')" 
        label="{{ 'testingPositions' | translate }}">
        <mat-select 
          class="form_control"
          formControlName="cover_directions" 
          disableOptionCentering 
          panelClass="dropdown"
          name="cover_directions"
          aria-label="{{ 'testingPositions' | translate }}">
          <mat-option *ngFor="let position of testingPositions" [value]="position.value">
            {{ position.label | translate }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithDefaultDigitalOcclusion(selectedTestStrategy)"
        [control]="form.get('cover_digitally_occluded')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="cover_digitally_occluded"
          name="cover_digitally_occluded"
          id="cover_digitally_occluded">
        <label 
          for="cover_digitally_occluded"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('cover_digitally_occluded').errors && form.get('cover_digitally_occluded').touched }">
          {{ 'coverDigitallyOccluded' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="testFormService.isTestWithFoveal(selectedTestStrategy)"
        [control]="form.get('test_foveal_sensitivity')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="test_foveal_sensitivity"
          id="test_foveal_sensitivity"
          name="test_foveal_sensitivity">
        <label 
          for="test_foveal_sensitivity"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('test_foveal_sensitivity').errors && form.get('test_foveal_sensitivity').touched }">
          {{ 'testFovealSensitivity' | translate }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper 
        *ngIf="testFormService.isTestWithSkipCalibration(selectedTestGroup, selectedTestStrategy, selectedTestProtocol, selectedControlType)"
        [control]="form.get('skip_calibration')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="skip_calibration"
          name="skip_calibration"
          id="skip_calibration">
        <label 
          for="skip_calibration"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('skip_calibration').errors && form.get('skip_calibration').touched }">
          {{ 'skipCalibration' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <app-input-wrapper 
        *ngIf="testFormService.isTestWithSkipEyeTrackingCalibration(selectedTestGroup, selectedTestStrategy)"
        [control]="form.get('skip_eye_tracking_calibration')">
        <input
          type="checkbox"
          class="checkbox_input"
          formControlName="skip_eye_tracking_calibration"
          name="skip_eye_tracking_calibration"
          id="skip_eye_tracking_calibration">
        <label 
          for="skip_eye_tracking_calibration"
          class="checkbox_label"
          [ngClass]="{ 'checkbox_label--error': form.get('skip_eye_tracking_calibration').errors && form.get('skip_eye_tracking_calibration').touched }">
          {{ 'skipEyeTrackingCalibration' | translate | titlecase }}
        </label>
      </app-input-wrapper>

      <button 
        *ngIf="isNew"
        type="button"
        class="button button--primary form_button"
        (click)="onBundleItemSave('forward')"
        [disabled]="!form.valid">
        <span class="regular">{{ 'next' | translate }}</span>
      </button>

    </ng-container>

    <ng-template #newTestTemplate>
      <div [hidden]="currentStep !== 1">
        {{ testBundlesService.canAddTestForBundle(bundledTests) ? ('wouldCreateNew' | translate) : ('maximumTestCountForBundle' | translate) }}
        <button 
          *ngIf="testBundlesService.canAddTestForBundle(bundledTests)" 
          class="button button--primary form_button"
          (click)="onNextTestCreate()">
          <span class="regular">{{ 'yes' | translate }}</span>
        </button>
        <button 
          class="button button--primary form_button"
          (click)="onSubmit()">
          <span class="regular">{{ testBundlesService.canAddTestForBundle(bundledTests) ? ('submitCustomBundle' | translate) : ('done' | translate) }}</span>
        </button>
      </div>
    </ng-template>


  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>
