// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from '../../core/services/tests.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { AudiologyRecord, MonitorEvent } from '../../models';

//animation
import { fadeAnimation, startList } from '../../animations/animations';

// constant
import { MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT, EAR } from '../../constants';

const MAX_EAR_THRESHOLD_COUNT = 4;

@Component({
  selector: 'app-monitor-audiology',
  templateUrl: './monitor-audiology.component.html',
  animations: [fadeAnimation, startList]
})
export class MonitorAudiologyComponent extends MonitorBaseComponent {

  rightEarRecords: AudiologyRecord[] = [];
  leftEarRecords: AudiologyRecord[] = [];

  leftLastElementIndex: number = 0;
  rightLastElementIndex: number = 0;
  leftThresholdSeqNum: number = null;
  rightThresholdSeqNum: number = null;

  isLoading: boolean = true;

  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.VISUAL_ACUITY * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.monitorTestService.getAudiologyState(this.test.id)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(records => {
        this.renderRecords(<AudiologyRecord[]>records);
      });
  }

  private renderRecords(records: AudiologyRecord[]) {
    records.filter(record => record.ear === EAR.RIGHT).forEach(record => {
      this.rightEarRecords[record.seq_num - 1] = record;
    });
    records.filter(record => record.ear === EAR.LEFT).forEach(record => {
      this.leftEarRecords[record.seq_num - 1] = record;
    });

    this.leftLastElementIndex = this.getLastElementIndex(this.leftEarRecords);
    this.rightLastElementIndex = this.getLastElementIndex(this.rightEarRecords);

    this.calculateThresholdSeqNums();
  }

  private getLastElementIndex(earRecords: AudiologyRecord[]): number {
    return earRecords.length - 1 - earRecords.findIndex(el => !!el);
  }

  private calculateThresholdSeqNums() {
    const rightThresholds = this.rightEarRecords.filter(record => record?.threshold);
    this.rightThresholdSeqNum = (rightThresholds.length >= MAX_EAR_THRESHOLD_COUNT)
      ? Math.max(...rightThresholds.map(item => item.seq_num))
      : null;

    const leftThresholds = this.leftEarRecords.filter(record => record?.threshold);
    this.leftThresholdSeqNum = (leftThresholds.length >= MAX_EAR_THRESHOLD_COUNT)
      ? Math.max(...leftThresholds.map(item => item.seq_num))
      : null;
  }

  public getPercentageDone() {
    const numOfThresholds = this.rightEarRecords.concat(this.leftEarRecords).filter(r => !!r.threshold).length;
    if (numOfThresholds * 12.5 > 100)
      return 100;
    return numOfThresholds * 12.5;
  }

  public calculateDimensions() { }

  public handleNewRecordEvent(event: MonitorEvent) {
    // todo add event handler
    if (event.type === MONITOR_EVENT_TYPE.NEW_AUDIOLOGY_RECORD)
      this.updateRecords(<AudiologyRecord>event.data);
  }

  private updateRecords(record: AudiologyRecord) {
    switch (record.ear) {
      case EAR.RIGHT:
        this.updateEarRecord(record, this.rightEarRecords);
        this.rightLastElementIndex = this.getLastElementIndex(this.rightEarRecords);
        break;
      case EAR.LEFT:
        this.updateEarRecord(record, this.leftEarRecords);
        this.leftLastElementIndex = this.getLastElementIndex(this.leftEarRecords);
        break;
    }

    this.calculateThresholdSeqNums();
  }

  private updateEarRecord(record: AudiologyRecord, eyeData: AudiologyRecord[]) {
    if (!eyeData[0] || (record.seq_num > eyeData[0].seq_num))
      eyeData[record.seq_num - 1] = record;
  }

  isLeftEarRecordHidden(record: AudiologyRecord, index: number) {
    if (record)
      return false;

    if (!this.leftThresholdSeqNum)
      return index > this.leftLastElementIndex;

    const firstRecordIndex = this.leftEarRecords.length - 1 - this.leftThresholdSeqNum;
    return index > firstRecordIndex;
  }

  isRightEarRecordHidden(record: AudiologyRecord, index: number) {
    if (record)
      return false;

    if (!this.rightThresholdSeqNum)
      return index > this.rightLastElementIndex;

    const firstRecordIndex = this.rightEarRecords.length - 1 - this.rightThresholdSeqNum;
    return index > firstRecordIndex;
  }

  public resetStateAfterReconnection(): void {
    this.rightEarRecords = [];
    this.leftEarRecords = [];

    this.leftLastElementIndex = 0;
    this.rightLastElementIndex = 0;

    this.leftThresholdSeqNum = null;
    this.rightThresholdSeqNum = null;
  }

  public onToggleGrid() { }
}